import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Home.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Player } from "video-react";
import torg from '../Images/Torg verified B2B supplier.png'

export default function Home() {
  const [allBlog, setAllBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getBlog() {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `https://mrolive-back-end-2c3o.onrender.com/api/v1/blogs`,
        headers: { lang: "en" },
      });
      setAllBlog(response?.data?.data);
      setLoading(false);
    } catch (e) {
      console.e("Error fetching blog:", e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getBlog();
  }, []);

  var settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const player = {
    disableCompletely: true,
    disableDefaultControls: true,
    autoHide: true,
    clickable: false,
    hideControls: true,
    autoPlay: true,
    muted: true,
    loop: true,
    hideBigPlayButton: true,
  };

  return (
    <>
      <div style={{ height: "100%", overflow: "hidden" }}>
        <section
          className="hero-section parallax-container"
          style={{ position: "relative", backgroundColor: "black" }}
        >
          <Player
            {...player}
            id="parallaxVideo"
            src="https://firebasestorage.googleapis.com/v0/b/mrolive-eb608.appspot.com/o/videoplayback.mp4?alt=media&token=7d1ae838-a0e9-49a6-b457-50c5aec0b4c1"
            playsInline
            alt="Product"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "transform 0.3s ease-out",
            }}
          />
          <style>
            {`
          .video-react .video-react-control-bar {
            display: none !important;
          }
          .video-react .video-react-big-play-button{
            display: none !important;
          }
        `}
          </style>
        </section>
      </div>
      <div className="container  " style={{ paddingTop: "50px" }}>
        <div className="row">
          <div
            className="col-md-4 col-sm-12 text-center"
            style={{ marginBottom: "10px" }}
          >
            <h2>
              MR.OLIVE
              <br />
              FOR
              <br />
              FOOD
              <br />
              INDUSTRIES
            </h2>
            <button
              className="btn"
              style={{
                backgroundColor: "#4e732c",
                color: "white",
                width: "60%",
                marginTop: "70px",
              }}
              onClick={() =>
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/mrolive-eb608.appspot.com/o/Mr%20olive%20Catalogue.pdf?alt=media&token=94b39771-0fbb-414b-b435-96417e56ffca",
                  "_blank"
                )
              }
            >
              Show Catalogue <i className="fa-solid fa-angle-right"></i>
            </button>
          </div>
          <div className="col-md-4 col-sm-12" style={{ marginBottom: "10px" }}>
            <div
              style={{
                color: "white",
              }}
              className="p-2 homeContent"
            >
              <p>
                &nbsp; The company was established as a solidarity company in
                1998 in the field of production and processing of table olives.
                The production and manufacturing process is carried out using
                the latest production and packaging lines in accordance with
                international standards.
              </p>
              <p>
                &nbsp; The company has become a pioneer in the field of
                manufacturing and marketing products locally and globally, with
                a production capacity of 10,000 tons of olives. The company has
                tended to add other products such as peppers, vegetables,
                pickles and pickled artichokes.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="olive"></div>
          </div>
        </div>
      </div>
      <div className="container p-5">
        <div className="row p-0  gap-0 gap-sm-5">
          <div
            className="col-lg col-sm-12 circular  "
            style={{ paddingLeft: "0" }}
          >
            <div>
              <Link to="/products" style={{ textDecoration: "none" }}>
                <div className="container m-1">
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-4 bgSecond"
                      style={{ display: "inline-block", padding: "0" }}
                    >
                      <div className="olive1"></div>
                    </div>
                    <div
                      className="col-md-8 col-sm-8 text-bolder bgThird"
                      style={{ color: "#4e732c", display: "flex" }}
                    >
                      <div
                        className="bgFirst"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>PRODUCTS</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-lg col-sm-12 circular  "
            style={{ paddingLeft: "0" }}
          >
            <div>
              <Link to="/packages" style={{ textDecoration: "none" }}>
                <div className="container m-1">
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-4 bgSecond"
                      style={{ display: "inline-block", padding: "0" }}
                    >
                      <div className="olive2"></div>
                    </div>
                    <div
                      className="col-md-8 col-sm-8 text-bolder bgThird"
                      style={{ color: "#4e732c", display: "flex" }}
                    >
                      <div
                        className="bgFirst"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>PACKAGES</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-lg col-sm-12 circular "
            style={{ paddingLeft: "0" }}
          >
            <div>
              <Link to="/photoGallery" style={{ textDecoration: "none" }}>
                <div className="container m-1">
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-4 bgSecond"
                      style={{ display: "inline-block", padding: "0" }}
                    >
                      <div className="olive3"></div>
                    </div>
                    <div
                      className="col-md-8 col-sm-8 text-bolder bgThird"
                      style={{ color: "#4e732c", display: "flex" }}
                    >
                      <div
                        className="bgFirst"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>PHOTOGALLERY</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          {allBlog.length === 1 &&
            allBlog.map((blog) => (
              <div className="col-md-4" key={blog.id}>
                <a
                  href={blog.link}
                  target="_blank"
                  className="text-decoration-none text-black"
                >
                  <div className="card">
                    <div className="image pb-2">
                      <img
                        src={blog.image}
                        className="img-fluid rounded-start"
                        alt={blog.title}
                        style={{
                          height: "50vh",
                          width: "100%",
                        }}
                      />
                    </div>
                    <div className="content">
                      <span className="title fs-4 fw-bold pb-2 ps-2">
                        {blog.name}
                      </span>
                      <p className="desc text-start ps-2">{blog.shortDesc}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          {allBlog.length > 1 && (
            <Slider {...settings}>
              {allBlog &&
                allBlog.map((blog) => (
                  <div key={blog.id}>
                    <a
                      href={blog.link}
                      target="_blank"
                      className="text-decoration-none text-black"
                    >
                      <div
                        className="card"
                        style={{
                          height: "65vh",
                        }}
                      >
                        <div className="image pb-2">
                          <img
                            src={blog.image}
                            className="img-fluid rounded-start"
                            alt={blog.title}
                          />
                        </div>
                        <div className="content">
                          <span className="title fs-4 fw-bold pb-2 ps-2">
                            {blog.name}
                          </span>
                          <p className="desc text-start ps-2">
                            {blog.shortDesc}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </Slider>
          )}
          {loading && (
            <section className="dots-container d-flex justify-content-center align-atiems-center">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </section>
          )}
        </div>
      </div>
      <div className="torgContainer">
        <a href="https://usetorg.com/suppliers/mr-olive" target="_blank">
        <img src={torg} className="torgImage"/></a>
      </div>
    </>
  );
}
